// External Libraries
import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";

// Components
import { HotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { SummarizedHotelCard } from "components/flexComponents/Hotels/components/SummarizedHotelCard/SummarizedHotelCard";
import IsomorphicCarousel from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { SummarizedHotelsCommonView } from "components/flexComponents/Hotels/components/SummarizedHotelCard/SummarizedHotelsCommonView";
import { useMapPropertySearchById } from "src/components/flexComponents/Hotels/hooks/useMapPropertySearchById";
import { withStores } from "src/stores";
import { hotelStructuredData } from "components/flexComponents/Hotels/utils/getHotelStructuredData";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { SeeAllContentLink, SHOW_MORE_STRATEGY } from "components/flexComponents/Hotels/components/SeeAllContentLink";

// Types
import { HotelsProps, SummarizedHotelsQueryComponentProps } from "components/flexComponents/Hotels/typings";
import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";

// Uitk
import { UitkSpacing } from "@egds/react-core/spacing";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { egRecsClickstreamPresentedEvent } from "components/flexComponents/Hotels/utils/egRecsEvents";

import {
  AFFINITY_DESTINATION_LANDING_PAGE_TYPES,
  EG_RECS_STRATEGY,
} from "components/flexComponents/Hotels/utils/constants";
import { InView } from "react-intersection-observer";
import { affinityProductsModulePresentedClickstreamEvent } from "../utils/affinityClickstreamEvents";

const SummarizedHotelsCarouselTopImage: React.FC<HotelsProps> = (props: HotelsProps) => {
  const { templateComponent, context, hotels, flexModuleModelStore } = props;

  const {
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as HotelsFlexModuleResult | null;

  if (!model?.hotels?.length) {
    return null;
  }

  return (
    <SummarizedHotelsCarouselQueryComponent
      templateComponent={templateComponent}
      context={context}
      model={model}
      hotels={hotels}
    />
  );
};

const SummarizedHotelsCarouselQueryComponent = (props: SummarizedHotelsQueryComponentProps) => {
  const { templateComponent, context, model, hotels: hotelsStore } = props;
  const { site } = context;
  const { callToActionStyle, showMoreStrategy, topCityIndex } = model;
  const track = useEgClickstream();

  const onChangeFireAffinityProductsModulePresentedEvent = (inView: boolean) => {
    const { searchContext } = context;
    const { pageType, affinity, location } = searchContext;

    const isAffinityDestinationLandingPage = AFFINITY_DESTINATION_LANDING_PAGE_TYPES.includes(pageType);
    if (affinity && isAffinityDestinationLandingPage && inView) {
      const event = affinityProductsModulePresentedClickstreamEvent(
        location?.id?.toString(),
        location?.name,
        model.hotels?.length,
        affinity.code
      );
      track(event);
    }
  };

  const isEgRecsStrategy = Boolean(EG_RECS_STRATEGY.includes(model.strategy));
  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const fmId = getFmId(templateComponent);

  const { formatText } = useLocalization();
  useMapPropertySearchById(model, hotelsStore, context);

  const onItemViewedCallback = (index: number) => {
    const isNewPresentedBehaviour = true;
    if (isEgRecsStrategy) {
      const event = egRecsClickstreamPresentedEvent(model, [model.hotels[index]], index, isNewPresentedBehaviour);
      track(event);
    }
  };

  return (
    <LazyLoad context={context}>
      <InView as="div" onChange={onChangeFireAffinityProductsModulePresentedEvent} triggerOnce>
        <HotelsContext.Provider value={{ context, templateComponent, model }}>
          <SchemaOrgScript structuredData={hotelStructuredData(model.hotels, topCityIndex, site.domain)} />
          <UitkSpacing padding={{ block: "three" }}>
            <div
              className="SummarizedHotelsCarouselTopImage Hotels"
              id={id}
              data-fm={fmId}
              data-fm-title-id={fmTitleId}
            >
              <SummarizedHotelsCommonView>
                <UitkSpacing padding={{ blockstart: "three" }}>
                  <IsomorphicCarousel
                    itemsVisible={{ sm: 1, md: 3, lg: 3 }}
                    itemViewedCallback={onItemViewedCallback}
                    pageBy={1}
                    peek
                    buttonText={{
                      nextButton: formatText("carousel.item.next"),
                      prevButton: formatText("carousel.item.prev"),
                    }}
                    itemsMaxHeight
                    wrapItems
                  >
                    {model.hotels.map((hotel, index) => {
                      const cardProps = {
                        isTopImage: true,
                        showPrice: false,
                        showAddress: false,
                        showNeighborhood: true,
                        showDistance: true,
                        showRating: true,
                        key: hotel.hotelId,
                        hotel,
                        index,
                        callToActionStyle,
                      };

                      return (
                        <Viewport key={`summarized-hotels-carousel-top-image-${hotel.hotelId}`}>
                          <ViewSmall>
                            <SummarizedHotelCard {...cardProps} isMobile />
                          </ViewSmall>
                          <ViewMedium>
                            <SummarizedHotelCard {...cardProps} isMobile={false} />
                          </ViewMedium>
                        </Viewport>
                      );
                    })}
                  </IsomorphicCarousel>
                </UitkSpacing>
                {showMoreStrategy === SHOW_MORE_STRATEGY.BOTTOM_LINK && (
                  <UitkSpacing padding={{ block: "three" }}>
                    <div>
                      <SeeAllContentLink model={model} context={context} />
                    </div>
                  </UitkSpacing>
                )}
              </SummarizedHotelsCommonView>
            </div>
          </UitkSpacing>
        </HotelsContext.Provider>
      </InView>
    </LazyLoad>
  );
};

export const SummarizedHotelsCarouselTopImageView = withStores(
  "hotels",
  "context",
  "flexModuleModelStore"
)(observer(SummarizedHotelsCarouselTopImage));

export default SummarizedHotelsCarouselTopImageView;
